<template>
  <div
    id="content"
    class="vente"
  >
    <Nav />
    <h2>
      Louer un vélo à
      <span class="gradient-text">Bischoffsheim</span>
    </h2>
    <section>
      <p
        v-if="produits && produits.length === 0"
        class="empty"
      >
        Il n'y a pas de vélos en location pour le moment.
      </p>
      <ul
        v-else
        class="products"
      >
        <li
          v-for="produit in produits"
          :key="produit.id"
        >
          <BikeProduct
            :infos="produit"
            location
          />
        </li>
      </ul>
    </section>
    <Split />
  </div>
</template>

<script>
import Nav from "@/components/Nav.vue";
import Split from "@/components/Split.vue";
import BikeProduct from "@/components/BikeProduct.vue";
import config from "@/config";

export default {
  name: "Location",
  components: {
    Nav,
    Split,
    BikeProduct,
  },
  data() {
    return {
      produits: null,
      selectedProduct: null,
    };
  },
  computed: {
    config() {
      return config;
    },
  },
  mounted() {
    this.$store.dispatch("changeColor", true);

    fetch(`${config.api}/api/produits_location`)
      .then((res) => res.json())
      .then((res) => {
        this.produits = res.results;
      });
  },
};
</script>

<style lang="scss">
.vente {
  .empty {
    text-align: center;
    margin-bottom: 10rem;
  }
}
</style>
