<template>
  <section
    id="marques"
  >
    <h2>
      Nos partenaires
    </h2>
    <DynamicMarquee
      direction="row"
      :speed="{type: 'pps', number: speed, }"
      :hoverPause="false"
      >
      <!-- pause -->
      <figure
        v-for="marque in marques"
        :key="marque.nom"
      >
        <!-- class="glass" -->
        <img
          :alt="marque.nom"
          :title="marque.nom"
          :src="marque.logo"
          loading="lazy"
        />
        <figcaption>{{ marque.nom }}</figcaption>
      </figure>
    </DynamicMarquee>
  </section>
</template>

<script>
import DynamicMarquee from "vue-dynamic-marquee";

export default {
  name: "Marques",
  components: {
    DynamicMarquee,
  },
  data() {
    return {
      marques: [
        {
          nom: "Focus",
          logo: "/marques/focus.jpeg",
        },
        {
          nom: "Levit",
          logo: "/marques/levit.jpeg",
        },
        {
          nom: "BBB Cycling",
          logo: "/marques/bbb.jpeg",
        },
        {
          nom: "Bertin",
          logo: "/marques/bertin.jpeg",
        },
        {
          nom: "Bosh",
          logo: "/marques/bosh.jpeg",
        },
        {
          nom: "DT Swiss",
          logo: "/marques/dt_swiss.jpeg",
        },
        {
          nom: "Magura",
          logo: "/marques/magura.jpeg",
        },
        {
          nom: "Scope",
          logo: "/marques/scope.jpeg",
        },
        {
          nom: "Shimano",
          logo: "/marques/shimano.jpeg",
        },
        {
          nom: "Ultima",
          logo: "/marques/ultima.jpeg",
        },
      ].reverse(),
      speed: 1000,
    };
  },
  mounted() {
    setInterval(() => {
      this.speed = 100;
    }, 1000);
  },
};
</script>

<style lang="scss">
@use "@/assets/styles/variables.scss" as *;

#marques {
  > div {
    margin-top: 3rem;
    height: 18rem !important;
    @include media_small() {
      height: 13rem !important;
    }
  }
  > div > div {
    display: flex;
    gap: 2rem;
    > figure {
      margin: 0;
      height: 15rem;
      width: 15rem;

      @include media_small() {
        height: 10rem;
        width: 10rem;
      }
      background: white;
      border-radius: 1rem;
      figcaption {
        text-align: center;
      }
    }
  }
  img {
    object-fit: contain;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }
}
</style>
