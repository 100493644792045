<template>
  <section id="contact">
    <i class="fa fa-phone" />
    <h2 class="spaced">Contact</h2>

    <div class="boxes">
      <div
        id="mail_tel"
        class="glass"
      >
        <div class="item">
          <strong>Par mail</strong>
          <p>
            <a
              :href="`mailto:${config.email}`"
              title="Nous envoyer un mail"
            >
              {{config.email}}
            </a>
          </p>
        </div>
        <div class="item tel">
          <strong>Par téléphone</strong>
          <p class="text-center">
            Magasin<br />
            <a
              :href="`tel:${config.telMag}`"
              title="Nous appeler au magasin"
            >
              {{config.telMag}}
            </a>
          </p>
          <p class="text-center">
            Mobile<br />
            <a
              :href="`tel:${config.tel}`"
              title="Nous appeler sur mobile"
            >
              {{config.tel}}
            </a>
          </p>
        </div>
      </div>
      <div
        id="reseaux"
        >
        <a
          href="https://www.facebook.com/CycloTruckMolsheimStrasbourg"
          target="_blank"
          title="Notre page Facebook"
        >
          <i class="fab fa-facebook" />
          <legend>@CycloTruckMolsheimStrasbourg</legend>
        </a>
        <a
          href="https://www.instagram.com/cyclotruck"
          target="_blank"
          title="Notre page Instagram"
        >
          <i class="fab fa-instagram" />
          <legend>@cyclotruck</legend>
        </a>
      </div>
      <div
        id="adresse"
        class="glass"
      >
        <a
          target="_blank"
          href="https://maps.app.goo.gl/HXPDu6vCM6mEZM3W9"
          title="Voir sur Google Maps"
        >
          <ul translate="no">
            <li class="m-b"><strong>Le Shop</strong></li>
            <li>Cyclo Truck</li>
            <li>63 Rue Principale</li>
            <li>67870 Bischoffsheim</li>
            <li>France</li>
          </ul>
        </a>
      </div>
    </div>
  </section>
</template>

<script>
import config from "@/config";

export default {
  name: "Contact",
  computed: {
    config() {
      return config;
    },
  },
};
</script>

<style lang="scss">
@use "@/assets/styles/variables.scss" as *;

#contact {
  .boxes {
    display: flex;
    justify-content: space-around;
    align-items: center;
    gap: 2rem;
    @include media-small() {
      flex-direction: column;
    }
  }
  .glass {
    padding: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .item {
    text-align: center;
  }

  #reseaux, #mail_tel, #adresse {
    display: flex;
    flex-direction: column;
    justify-content: center;

    align-items: center;
    gap: 1rem;

    @include media-small() {
      flex-direction: row;
      min-width: 75%;
      justify-content: space-around;
    }

    a {
      display: flex;
      align-items: center;
      gap: 1rem;
      color: inherit;
      text-decoration: none;
      &:hover {
        @include gradient-text();
      }
      i {
        font-size: 5rem;
      }
    }
  }

  @include media-xsmall() {
    #reseaux {
      flex-direction: column;
    }
  }

  #adresse {
    height: fit-content;
    ul {
      list-style: none;
      padding: 0;
      margin: 0;
      li {
        margin-bottom: 0.5rem;
        text-align: center;
        &:first-of-type {
          margin-bottom: 2rem;
        }
      }
    }
  }

  position: relative;
  .fa-phone {
    @include bg-icon(15rem);
    top: -5rem;
    left: 10rem;
    transform: rotate(0deg);
  }
}
</style>
