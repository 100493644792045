import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

Vue.config.productionTip = false;

window.notPreRendered = true;
window.snapSaveState = () => {
  document.querySelector("#app").setAttribute("data-server-rendered", "true");
  window.notPreRendered = false;
};

new Vue({
  name: "Main",
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
