<template>
  <div
    id="content"
    class="vente"
  >
    <Nav />
    <h2>Nos vélos</h2>
    <section>
      <ul class="products">
        <li
          v-for="produit in produits"
          :key="produit.id"
        >
          <BikeProduct :infos="produit" />
        </li>
      </ul>
    </section>
    <Split />
  </div>
</template>

<script>
import Nav from "@/components/Nav.vue";
import BikeProduct from "@/components/BikeProduct.vue";
import Split from "@/components/Split.vue";
import config from "@/config";

export default {
  name: "VenteNew",
  components: {
    Nav,
    BikeProduct,
    Split,
  },
  data() {
    return {
      produits: [],
    };
  },
  computed: {
    config() {
      return config;
    },
  },
  mounted() {
    fetch(`${config.api}/api/produits`)
      .then((res) => res.json())
      .then((res) => {
        this.produits = res.results;
      });
  },
};
</script>

<style lang="scss">
@use "@/assets/styles/variables.scss" as *;

.vente {
  padding-top: 20rem;
}

.products {
  padding: 0;
  margin-top: 2rem;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  gap: 2rem;
  list-style: none;
  li {
    grid-column: span 4;
  }
}

@include media-medium() {
  .products li {
    grid-column: span 6;
  }
}

@include media-nonav() {
  .vente {
    padding-top: 5rem;
  }
}

@include media-xsmall() {
  .products li {
    grid-column: span 12;
  }
}
</style>
