import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    lightMode: localStorage.getItem("lightMode") === "true" || localStorage.getItem("lightMode") === true || false,
    notPreRendered: !!!window.__PRERENDER_INJECTED, // eslint-disable-line
  },
  mutations: {
  },
  getters: {
    notPreRendered(state) {
      return state.notPreRendered;
    },
    lightMode(state) {
      return state.lightMode;
    },
  },
  actions: {
    changeColor({ state }, noChange) {
      if (!noChange) {
        state.lightMode = !state.lightMode;
        localStorage.setItem("lightMode", state.lightMode);
      }

      const black = "#010912";
      const blackSplit = "#01091242";
      const white = "#E0E0E0";
      const whiteSplit = "#e0e0e029";
      let ascent = "#75b4cf";
      let secondary = "#27aae1";
      // dark
      const ascentLight5 = "#88bed6";
      const ascentLight10 = "#9bc9dc";
      const ascentLight15 = "#aed3e3";
      const ascentLight20 = "#c1dde9";
      const ascentLight45 = "#e5f1f7";
      const ascentDark30 = "#2c667f";
      const blackLight5 = "#02152a";
      const blackLight30 = "#0951a3";

      // light
      if (state.lightMode) {
        ascent = "#186181";
        secondary = "#116e96";
      }
      const ascentDark5 = "#14516b";
      const ascentDark10 = "#104156";
      const ascentDark15 = "#0c3040";
      const ascentDark20 = "#08202b";
      const ascentDark45 = black;
      const ascentLight30 = "#53b4df";
      const whiteDark5 = "#d3d3d3";
      const whiteDark30 = "#939393";

      if (state.lightMode) {
        document.documentElement.style.setProperty("--black-color", white);
        document.documentElement.style.setProperty("--black-color-split", whiteSplit);
        document.documentElement.style.setProperty("--white-color", black);
        document.documentElement.style.setProperty("--white-color-split", blackSplit);
        document.documentElement.style.setProperty("--ascent-color", ascent);
        document.documentElement.style.setProperty("--ascent-color-light-5", ascentDark5);
        document.documentElement.style.setProperty("--ascent-color-light-10", ascentDark10);
        document.documentElement.style.setProperty("--ascent-color-light-15", ascentDark15);
        document.documentElement.style.setProperty("--ascent-color-light-20", ascentDark20);
        document.documentElement.style.setProperty("--ascent-color-light-45", ascentDark45);
        document.documentElement.style.setProperty("--ascent-color-dark-30", ascentLight30);
        document.documentElement.style.setProperty("--black-light-5", whiteDark5);
        document.documentElement.style.setProperty("--black-light-30", whiteDark30);
        document.documentElement.style.setProperty("--secondary-color", secondary);
      } else {
        document.documentElement.style.setProperty("--black-color", black);
        document.documentElement.style.setProperty("--black-color-split", blackSplit);
        document.documentElement.style.setProperty("--white-color", white);
        document.documentElement.style.setProperty("--white-color-split", whiteSplit);
        document.documentElement.style.setProperty("--ascent-color", ascent);
        document.documentElement.style.setProperty("--ascent-color-light-5", ascentLight5);
        document.documentElement.style.setProperty("--ascent-color-light-10", ascentLight10);
        document.documentElement.style.setProperty("--ascent-color-light-15", ascentLight15);
        document.documentElement.style.setProperty("--ascent-color-light-20", ascentLight20);
        document.documentElement.style.setProperty("--ascent-color-light-45", ascentLight45);
        document.documentElement.style.setProperty("--ascent-color-dark-30", ascentDark30);
        document.documentElement.style.setProperty("--black-light-5", blackLight5);
        document.documentElement.style.setProperty("--black-light-30", blackLight30);
        document.documentElement.style.setProperty("--secondary-color", secondary);
      }
    },
  },
  modules: {
  },
});
