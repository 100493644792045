<template>
  <section class="content">
    <Nav />
    <main class="product-details location-details">
      <h2>{{ produit.nom }}</h2>
      <div class="details">
        <figure>
          <img
            v-if="produit.image"
            :src="produit.image"
            alt="Image du vélo"
            loading="lazy"
          />
          <img
            v-if="produit.image2"
            :src="produit.image2"
            alt="Image du vélo"
            loading="lazy"
          />
          <img
            v-if="produit.image3"
            :src="produit.image3"
            alt="Image du vélo"
            loading="lazy"
          />
          <img
            v-if="produit.image4"
            :src="produit.image4"
            alt="Image du vélo"
            loading="lazy"
          />
        </figure>
        <div class="right">
          <div class="prices">
            <div>
              Louez-moi à partir de {{ produit.prix_ttc }}&nbsp;€ par jour
            </div>
          </div>
          <div class="calendar-container">
            <DateRangePicker
              :dateRange="dateRange"
              autoApply
              opens="inline"
              :ranges="false"
              single-date-picker="range"
              :locale-data="localeData"
              :minDate="new Date()"
              :date-format="dateFormat"
              @update="handleSelectDates"
            />
          </div>
          <div
            v-if="nbDays"
            class="total"
          >
            Durée de la réservation&nbsp;: {{ nbDays }} jour{{ nbDays > 1 ? "s" : "" }}
            <br />
            Total&nbsp;: {{ total }}&nbsp;€ TTC
            <br />
            <i class="infos">
              Le prix total peut varier en fonction de l'heure de retour, à définir lors de la récupération du vélo.
            </i>
          </div>
          <div class="button-container">
            <button
              class="button"
              :disabled="!dateRange.startDate || !dateRange.endDate || impossible"
              @click="modalOpen = true"
            >
              {{ impossible ? "Réservation impossible" : "Réserver"}}
            </button>
          </div>
          <div
            class="description"
            v-html="produit.description"
          />
        </div>
      </div>
    </main>
    <ModalLocation
      :is-open="modalOpen"
      :infos="infos"
      @submit="handleSubmit"
      @close="modalOpen = false; successLocation = false;"
      :success="successLocation"
      :loading="modalLoading"
    />
  </section>
</template>

<script>
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import Nav from "@/components/Nav.vue";
import config from "@/config";
import ModalLocation from "../components/ModalLocation.vue";

export default {
  name: "Ventes",
  components: {
    Nav,
    DateRangePicker,
    ModalLocation,
  },
  data() {
    return {
      produit: {},
      dateRange: {},
      localeData: {
        direction: "ltr",
        format: "dd/mm/yyyy HH:MM",
        separator: " - ",
        applyLabel: "Appliquer",
        cancelLabel: "Annuler",
        weekLabel: "S",
        customRangeLabel: "Plage personnalisée",
        daysOfWeek: ["Dim", "Lun", "Mar", "Mer", "Jeu", "Ven", "Sam"],
        monthNames: ["Jan", "Fév", "Mar", "Avr", "Mai", "Juin", "Juil", "Août", "Sep", "Oct", "Nov", "Déc"],
        firstDay: 0,
      },
      modalOpen: false,
      successLocation: null,
      impossible: false,
      modalLoading: false,
    };
  },
  computed: {
    config() {
      return config;
    },
    isDispo() {
      return this.produit.date_indispo === null;
    },
    prixPromo() {
      return parseFloat(this.produit.prix_ttc * (1 - (this.produit.promo / 100))).toFixed(2);
    },
    nbDays() {
      if (!this.dateRange.startDate) {
        return 0;
      }

      if (this.dateRange.startDate.getDate() === this.dateRange.endDate.getDate()) {
        return 1;
      }

      return Math.ceil((this.dateRange.endDate - this.dateRange.startDate) / (1000 * 60 * 60 * 24)) + 1;
    },
    total() {
      let total = this.nbDays * this.produit.prix_ttc;

      if (this.nbDays >= 5) {
        total *= 0.9;
      }

      return total.toFixed(2);
    },
    infos() {
      return {
        produit: this.produit.nom,
        duree: this.nbDays,
        total: this.total,
      };
    },
  },
  mounted() {
    fetch(`${config.api}/api/produits_location/${this.$route.params.id}/`)
      .then((res) => res.json())
      .then((res) => {
        this.produit = res;
      })
      .catch((err) => {
        console.error(err);
        this.$router.push("/");
      });
  },
  methods: {
    dateFormat(classes, date) {
      if (!this.produit || !this.produit.creneaux_indisponibles) {
        return classes;
      }

      this.produit.creneaux_indisponibles.forEach(([debut, fin]) => {
        const start = new Date(debut);
        const end = new Date(fin);

        if (date >= start && date <= end) {
          classes.disabled = true;
        }
      });
      return classes;
    },
    handleSelectDates(dateRange) {
      this.impossible = this.produit.creneaux_indisponibles.some(([debut, fin]) => {
        const dateDebut = new Date(debut);
        const dateFin = new Date(fin);

        return (dateRange.startDate >= dateDebut && dateRange.startDate <= dateFin)
          || (dateRange.endDate >= dateDebut && dateRange.endDate <= dateFin)
          || (dateRange.startDate <= dateDebut && dateRange.endDate >= dateFin);
      });

      this.dateRange = dateRange;
    },
    handleSubmit(data) {
      const body = {
        date_debut: this.dateRange.startDate,
        date_fin: this.dateRange.endDate,
        ...data,
      };

      this.modalLoading = true;
      fetch(
        `${config.api}/api/reservation_location/${this.$route.params.id}/`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify(body),
        },
      )
        .then((response) => {
          if (response.ok) {
            return response.json();
          }
          throw new Error();
        })
        .then((res) => {
          if (res.error) {
            console.error(res.error);
            this.successLocation = false;
            return;
          }

          this.successLocation = true;
        })
        .catch((err) => {
          console.error(err);
          this.successLocation = false;
        })
        .finally(() => {
          this.modalLoading = false;
        });
    },
  },
};
</script>

<style lang="scss">
@use "@/assets/styles/variables.scss" as *;

.vue-daterange-picker {
  color: black;
}
.location-details {
  .button-container {
    display: flex;
    justify-content: center;
    margin-top: 2rem;
    .button {
      width: 80%;
      cursor: pointer;
    }
  }
  .description {
    margin-top: 5rem;
  }
  .total {
    margin-top: 3rem;
    text-align: center;
  }
  .calendar-container {
    text-align: center;
    .reportrange-text {
      display: none;
    }
  }

  .infos {
    font-size: 1rem;
  }
}
</style>
