<template>
  <section id="prix">
    <h2
      v-if="false"
      class="spaced"
    >
      Forfaits d'entretien
    </h2>
    <div
      v-if="false"
      id="forfaits"
      class="boxes"
    >
      <div
        v-for="forfait in forfaits.filter((f, ) => !f.hide, )"
        :key="forfait.title"
        class="glass"
      >
        <div class="icon">
          <i
            class="fa"
            :class="[forfait.class, forfait.icon, ]"
          />
        </div>
        <table>
          <thead>
            <tr>
              <th colspan="2">{{forfait.title}} ({{ trimDecimalsIfNeeded(forfait.prix, ) }}€)</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="item in forfait.items"
              :key="item.id"
            >
              <td v-html="item.nom" />
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="spaced">
      <h2>
        Entretien & Réparation
      </h2>
      <h3>
        Les prix sont exprimés en TTC et hors pièces. Des ajustements peuvent avoir lieu.
      </h3>
    </div>
    <div id="tarifs">
      <i class="fa fa-hammer" />
      <div
        class="boxes"
      >
        <div
          v-for="groupe in groupes"
          :key="groupe.nom"
          class="glass"
        >
          <table>
            <thead>
              <tr>
                <th colspan="2">{{groupe.nom}}</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="tarif in groupe.tarifs"
                :key="tarif.nom"
              >
                <th>{{ tarif.nom }}</th>
                <td>{{ trimDecimalsIfNeeded(tarif.prix_ttc, ) }}&nbsp;€</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <p class="contact-more">
      Ceci est une liste non exhaustive de nos prestations<br />
      <a
        class="contact-scroll-link"
        href="#contact"
        @click.stop="scrollTo('contact',)"
        title="Contactez-nous pour un devis gratuit"
      >
        Contactez-moi
      </a>
      pour un devis gratuit.
    </p>
    </div>
  </section>
</template>

<script>
import config from "@/config";

export default {
  name: "Prix",
  data() {
    return {
      groupes: [],
      forfaits: [
        {
          hide: false,
          title: "Standard",
          icon: "fa-medal",
          class: "bronze",
          prix: "",
          items: [],
        },
        {
          hide: false,
          title: "Plus",
          icon: "fa-medal",
          class: "silver",
          prix: "38",
          items: [],
        },
        {
          hide: false,
          title: "Premium",
          icon: "fa-medal",
          class: "gold",
          prix: "58",
          items: [],
        },
      ],
    };
  },
  computed: {
    config() {
      return config;
    },
  },
  methods: {
    trimDecimalsIfNeeded(number) {
      try {
        return number.endsWith(".00") ? number.split(".")[0] : number;
      } catch (e) {
        return number;
      }
    },
  },

  mounted() {
    fetch(`${config.api}/api/tarifs`)
      .then((res) => res.json())
      .then((res) => {
        this.groupes = res.results;
      });

    fetch(`${config.api}/api/forfaits`)
      .then((res) => res.json())
      .then((res) => {
        res.results.forEach((bddForfait) => {
          const forfait = this.forfaits.find((f) => f.class === bddForfait.type);
          this.$set(forfait, "prix", bddForfait.prix_ttc);
          this.$set(forfait, "items", bddForfait.elements);
          this.$set(forfait, "title", bddForfait.nom);
        });

        this.forfaits.forEach((f) => {
          if (f.items.length === 0) {
            this.$set(f, "hide", true);
          }
        });
      });
  },
};
</script>

<style lang="scss">
@use "@/assets/styles/variables.scss" as *;

section#prix {
  padding: 0 10rem;
}
#forfaits {
  display: flex;
  gap: 2rem;
  > div {
    flex: 1;
    padding: 3rem 1rem;
  }
  .icon {
    text-align: center;
    font-size: 4rem;
    position: absolute;
    top: -3rem;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--black-color);
    border-radius: 50%;
    width: 6rem;
    height: 6rem;
    border-bottom: 3px solid rgba( 255, 255, 255, 0.4 );
  }
  table {
    width: 100%;
    // text-align: center;
  }
  th {
    font-weight: 900;
    padding-bottom: 2rem;
  }
  td {
    padding-bottom: 0.5rem;
  }
  .bronze {
    color: #bb6029;
  }
  .silver {
    color: #8e8e8e;
  }
  .gold {
    color: #f1d43b;
  }
}
#tarifs {
  table {
    border-collapse: collapse;
    width: 100%;
  }
  thead th {
    font-weight: 900;
    padding-bottom: 2rem;
  }
  th {
    padding: 0.3rem 1rem;
  }
  td {
    text-align: center;
  }
  // tbody tr:hover {
  //   background: rgba(0, 0, 0, 0.05);
  // }
  .boxes {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 2rem;
    @include media-small() {
      gap: 5rem;
    }
    & > * {
      padding: 1rem;
      min-width: 30%;
    }
  }

  .contact-more {
    font-weight: bold;
    margin-top: 2.5rem;
    text-align: center;
    a {
      color: var(--ascent-color);
      text-decoration: none;
      &:hover {
        @include gradient-text();
      }
    }
  }

  position: relative;
  .fa-hammer {
    @include bg-icon(15rem);
    bottom: 20rem;
    right: -5rem;
    transform: rotate(0deg);
  }
}
</style>
