<template>
  <router-link
    :to="{
      name: location ? 'location-details' : 'product-details',
      params: {'id': infos.id, },
    }"
    class="product glass"
    title="Voir le produit en détail"
    @click="$emit('click', )"
  >
    <div
      class="promo-tag"
    >
      {{infos.promo ? "Promotion" : "" }}
    </div>
    <strong class="title">{{ infos.nom }}</strong>
    <figure>
      <img
        :src="infos.image"
        alt="Image du vélo"
        loading="lazy"
      />
    </figure>

    <div
      v-if="location"
      class="bottom location"
    >
      <div class="prices">
        <div class="price">
          à partir de {{ infos.prix_ttc }}&nbsp;€ par jour
        </div>
      </div>
    </div>
    <div
      v-else
      class="bottom"
    >
      <div class="prices">
        <div class="price">
          <div :class="{barre: !!infos.promo, }">
            {{ infos.prix_ttc }}&nbsp;€ TTC
          </div>
          <div v-if="infos.promo">
            {{ prixPromo }}&nbsp;€ TTC
          </div>
        </div>
        <div v-if="infos.promo">
          -{{ infos.promo }}%
        </div>
      </div>
      <div
        class="disponibility"
        :class="isDispo ? 'green' : 'red'"
      >
        <i class="fa fa-circle" />
        {{ isDispo ? "Disponible" : "Indisponible" }}
      </div>
    </div>
  </router-link>
</template>

<script>
export default {
  name: "BikeProduct",
  props: {
    location: Boolean,
    infos: Object,
  },
  computed: {
    isDispo() {
      return this.infos.date_indispo === null;
    },
    prixPromo() {
      const prix = parseFloat(this.infos.prix_ttc * (1 - (this.infos.promo / 100))).toFixed(0);
      return `${prix}.00`;
    },
  },
};
</script>

<style lang="scss">
@use "@/assets/styles/variables.scss" as *;

.product {
  padding: 2rem 1rem;
  cursor: pointer;
  color: var(--white-color);
  text-decoration: none;
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  figure {
    position: relative;
    height: 20rem;
    margin-top: 4rem;
    margin-bottom: 2rem;
    backdrop-filter: drop-shadow(2px 4px 6px var(--black-color));
    padding: 1rem;
    border-radius: 1rem;
    overflow: hidden;
  }
  figure, .bottom {
    margin-left: 2rem;
    margin-right: 2rem;
  }
  .bottom {
    display: flex;
    justify-content: space-between;
    // flex-direction: row-reverse;
    align-items: center;
    gap: 1rem;
    &.location {
      justify-content: flex-end;
    }
  }
  .barre {
    text-decoration: line-through;
    font-weight: normal;
  }
  .promo-tag {
    text-align: center;
    @include gradient-text();
  }
  .prices {
    display: flex;
    align-items: center;
    gap: 1rem;
    .price > :last-child {
      font-weight: bold;
    }
  }

  img {
    height: 100%;
    width: 100%;
    object-fit: contain;
  }
  .label {
    margin-right: 0.5rem;
  }
  p {
    margin: 0;
  }
  .title {
    text-align: center;
    font-size: 2rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 0 1rem;
  }
}
</style>
