<template>
  <dialog
    id="modal_location"
    ref="modal"
  >
    <h2 v-if="success === null">
      Finaliser votre demande de réservation
    </h2>
    <h2 v-else-if="success === false">
      Une erreur est survenue
    </h2>
    <h2 v-else>
      Votre réservation est enregistrée
    </h2>

    <div
      v-if="loading"
      class="text-center"
    >
      Chargement en cours... Veuillez patienter.
    </div>
    <div
      v-else-if="success === true"
      class="success"
    >
      <img
        src="@/assets/img/bike.svg"
        alt="Succès"
      />
      <p>
        Votre réservation a bien été enregistrée.<br />
        Lorsqu'elle sera validée, vous recevrez un e-mail de confirmation à l'adresse suivante&nbsp;: {{ form.email }}.
      </p>
      <div>
        <button
          class="button"
          @click="emitClose"
        >
          Fermer
        </button>
      </div>
    </div>
    <div
      v-else-if="success === false"
      class="text-center"
    >
      <p>
        Si le problème persiste, veuillez nous contacter au {{ config.tel }}.
      </p>
      <button
        class="button"
        @click="emitClose"
      >
        Fermer
      </button>
    </div>
    <div
      v-else
      class="content"
    >
      <p class="infos">
        En cliquant sur "Confirmer ma réservation", vous acceptez nos
        <router-link
          :to="{name: 'cgl', }"
          target="_blank"
        >
          Conditions Générales de Location
        </router-link>
        et notre <a>Politique de Confidentialité</a>.
        Veuillez vérifier les informations de votre réservation avant de procéder.
      </p>
      <h3>Détails de la réservation</h3>
      <ul>
        <li>Produit&nbsp;: {{ infos.produit }}</li>
        <li>Durée de la location&nbsp;: {{ infos.duree }} jours</li>
        <li>Prix total&nbsp;: {{ infos.total }}</li>
      </ul>
      <h3>Informations de Facturation</h3>
      <p>
        Pour finaliser votre réservation, veuillez saisir vos informations de facturation ci-dessous.
        Ces informations seront utilisées uniquement pour cette transaction.
        Nous nous engageons à protéger votre vie privée et vos informations personnelles conformément
        à notre <a>Politique de Confidentialité</a>.
      </p>
      <form @submit.prevent="handleSubmit">
        <div class="double-fields">
          <div class="floating-label">
            <input
              placeholder="Nom*"
              required
              v-model="form.nom"
            />
            <label>Nom*</label>
          </div>
          <div class="floating-label">
            <input
              placeholder="Prénom*"
              required
              v-model="form.prenom"
            />
            <label>Prénom*</label>
          </div>
        </div>
        <div class="floating-label">
          <input
            placeholder="Nom de l’entreprise (facultatif)"
            v-model="form.entreprise"
          />
          <label>Nom de l’entreprise (facultatif)</label>
        </div>
        <div class="floating-label">
          <input
            placeholder="Numéro et nom de rue*"
            v-model="form.rue"
            required
          />
          <label>Numéro et nom de rue*</label>
        </div>
        <div class="double-fields">
          <div class="floating-label">
            <input
              placeholder="Code postal*"
              v-model="form.code_postal"
              required
            />
            <label>Code postal*</label>
          </div>
          <div class="floating-label">
            <input
              placeholder="Ville*"
              v-model="form.ville"
              required
            />
            <label>Ville*</label>
          </div>
        </div>
        <div class="double-fields">
          <div class="floating-label">
            <input
              placeholder="E-mail*"
              v-model="form.email"
              type="email"
              required
            />
            <label>E-mail*</label>
          </div>
          <div class="floating-label">
            <input
              placeholder="Téléphone*"
              v-model="form.telephone"
              type="tel"
              required
            />
            <label>Téléphone*</label>
          </div>
        </div>

        <div class="checkboxes">
          <label>
            <input
              type="checkbox"
              v-model="acceptConfidentiality"
              required
            />
            J'accepte que mes informations de facturation soient traitées pour cette transaction conformément à la
            <a>Politique de Confidentialité</a>.
          </label>
          <label>
            <input
              type="checkbox"
              v-model="form.accept_offers"
            />
            J'aimerais recevoir des offres spéciales et des mises à jour par e-mail.
          </label>
        </div>

        <div class="footer">
          <button
            class="button"
            @click="emitClose"
          >
            Fermer
          </button>
          <button
            class="button success"
            type="submit"
            :disabled="submitDisabled"
          >
            Valider
          </button>
        </div>
      </form>

    </div>
  </dialog>
</template>

<script>
import config from "@/config";

export default {
  name: "ModalLocation",
  props: {
    isOpen: {
      type: Boolean,
      required: true,
    },
    infos: {
      type: Object,
      default: () => null,
    },
    success: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      form: {
        // pays: "France",
        nom: "",
        prenom: "",
        entreprise: "",
        rue: "",
        code_postal: "",
        ville: "",
        email: "",
        telephone: "",
        accept_offers: false,
      },
      acceptConfidentiality: false,
    };
  },
  computed: {
    submitDisabled() {
      return !(
        this.acceptConfidentiality
        && this.form.nom
        && this.form.prenom
        && this.form.rue
        && this.form.code_postal
        && this.form.ville
        && this.form.email
        && this.form.telephone
      );
    },
    config() {
      return config;
    },
  },
  watch: {
    isOpen: {
      handler(newValue) {
        if (newValue) {
          this.openModal();
        } else {
          this.closeModal();
        }
      },
      immediate: false,
    },
  },
  mounted() {
  },
  methods: {
    openModal() {
      this.$nextTick(() => {
        this.$refs.modal.showModal();
      });
    },
    closeModal() {
      this.$refs.modal.close();
    },
    emitClose() {
      this.$emit("close");
    },
    handleSubmit() {
      this.$emit("submit", this.form);
    },
  },
};
</script>

<style lang="scss">
@use "@/assets/styles/variables.scss" as *;

dialog {
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 20px;
  width: 75%;
  &::backdrop {
    background-color: rgba(0, 0, 0, 0.5);
  }
  .footer {
    display: flex;
    gap: 1rem;
    justify-content: center;
  }
}

/* Stylise le backdrop */

#modal_location {
  h2 {
    font-size: 3rem;
  }

  form {
    padding: 2rem 25%;
    display: flex;
    flex-direction: column;
    gap: 2.5rem;
    @include media-small() {
      padding: 2.5rem 15%;
    }
  }

  a {
    color: var(--ascent-color);
    text-decoration: underline;
  }

  .floating-label {
    border: 1px solid var(--ascent-color-light-10);
    margin-bottom: 0;
    width: 100%;
    label {
      border-bottom: 0;
      top: -1.2rem;
    }
  }

  .infos {
    text-align: center;
  }

  .success {
    text-align: center;
    ul {
      list-style: none;
    }
    img {
      margin: 2rem 0;
      width: 25%;
    }
  }

  .double-fields {
    display: flex;
    @include media-small() {
      flex-direction: column;
    }
    .floating-label {
      flex: 1;
      @include media-small() {
        &:not(:last-child) {
          margin-bottom: 2.5rem;
        }
      }
      &:not(:last-child) {
        margin-right: 1rem;
      }
    }
  }

  .checkboxes {
    display: flex;
    flex-direction: column;
  }
}
</style>
