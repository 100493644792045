<template>
  <section class="content">
    <Nav />
    <main class="product-details">
      <h2>{{ produit.nom }}</h2>
      <div class="details">
        <figure>
          <img
            v-if="produit.image"
            :src="produit.image"
            alt="Image du vélo"
            loading="lazy"
          />
          <img
            v-if="produit.image2"
            :src="produit.image2"
            alt="Image du vélo"
            loading="lazy"
          />
          <img
            v-if="produit.image3"
            :src="produit.image3"
            alt="Image du vélo"
            loading="lazy"
          />
          <img
            v-if="produit.image4"
            :src="produit.image4"
            alt="Image du vélo"
            loading="lazy"
          />
          <span
            v-if="produit.promo"
            class="promo-tag"
          >
            Promotion
          </span>
        </figure>
        <div class="right">
          <div
            class="disponibility"
            :class="isDispo ? 'green' : 'red'"
          >
            <i class="fa fa-circle" />
            {{ isDispo ? "Disponible" : "Indisponible" }}
          </div>

          <div class="prices">
            <div>
              <div>
                <strong :class="{barre: !!produit.promo, }">{{ produit.prix_ttc }}&nbsp;€ TTC</strong>
                <strong v-if="produit.promo">
                  -{{ produit.promo }}%
                </strong>
              </div>
              <strong
                v-if="produit.promo"
                class="promo"
              >
                {{ prixPromo }}&nbsp;€ TTC
              </strong>
            </div>
          </div>
          <div
            class="description"
            v-html="produit.description"
          />
          <a
            class="contact-product"
            :href="`tel:${config.tel}`"
            title="Nous appeler"
          >
            Contactez nous&nbsp;:<br/>
            <a
              :href="`tel:${config.tel}`"
              title="Nous appeler"
            >
              {{config.tel}}
            </a>
            -
            <a
              :href="`mailto:${config.email}`"
              title="Envoyer un email"
            >
              {{config.email}}
            </a>
          </a>
        </div>
      </div>
    </main>
  </section>
</template>

<script>
import Nav from "@/components/Nav.vue";
import config from "@/config";

export default {
  name: "Ventes",
  components: {
    Nav,
  },
  data() {
    return {
      produit: {},
    };
  },
  computed: {
    config() {
      return config;
    },
    isDispo() {
      return this.produit.date_indispo === null;
    },
    prixPromo() {
      return parseFloat(this.produit.prix_ttc * (1 - (this.produit.promo / 100))).toFixed(2);
    },
  },
  mounted() {
    fetch(`${config.api}/api/produits/${this.$route.params.id}/`)
      .then((res) => res.json())
      .then((res) => {
        this.produit = res;
      });
  },
};
</script>

<style lang="scss">
@use "@/assets/styles/variables.scss" as *;

.product-details {
  display: flex;
  flex-direction: column;
  .details {
    display: flex;
    gap: 2rem;
    @include media-medium() {
      flex-direction: column;
    }
    & > * {
      flex: 1;
    }
  }

  .prices {
    margin-bottom: 3rem;
    font-size: 2rem;
  }

  h2 {
    margin-bottom: 5rem;
  }

  figure {
    margin: 0;;
  }

  .right {
    padding: 2rem;
    border-radius: 1rem;
    background: radial-gradient(
        94.91% 94.91% at 50% 50%, rgba(1, 69, 137, 0.22) 0%, rgba(0, 0, 0, 0) 100%
      ), rgba(5, 24, 48, 0.2) !important;
  }

  img {
    width: 100%;
    border-radius: 1rem;
    background: white;
    @include media-medium() {
      width: calc(50% - 2rem);
      margin: 1rem;
    }
  }

  .description {
    * {
      color: var(--white-color) !important;
      background: transparent !important;
    }

    @include media-medium() {
    }

    td {
      background: radial-gradient(
        94.91% 94.91% at 50% 50%, rgba(1, 69, 137, 0.22) 0%, rgba(0, 0, 0, 0) 100%
      ), rgba(5, 24, 48, 0.2) !important;
    }

    table {
      width: 100% !important;
    }
  }

  .contact-product {
    color: var(--white-color);
    text-decoration: none;
  }
  .contact-product a {
    color: var(--white-color);
  }
}
</style>
