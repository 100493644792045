<template>
  <section id="preview_ventes">
    <h2>Nos vélos disponibles à la vente</h2>
    <h3>Trouvez votre fidèle destrier</h3>
    <div class="carousel">
      <Carousel
        ref="carousel"
        class="carousel-multiple"
      >
        <Slide
          v-for="produit in produits"
          class="glass carousel-slide"
          :key="produit.id"
          @mouseover="hover = true"
          @mouseleave="hover = false"
        >
          <router-link
            :to="{name: 'product-details', params: {id: produit.id, }, }"
            title="Voir le produit en détail"
          >
            <div
              @mouseover="hover = true"
              @mouseleave="hover = false"
            >
              <div class="promo">
                {{ produit.promo ? `Promotion -${produit.promo}%` : '' }}
              </div>
              <figure>
                <img
                  :src="produit.image"
                  :alt="produit.nom"
                  :title="produit.nom"
                  loading="lazy"
                />
              </figure>
              <div class="title">
                {{ produit.nom.length > 28 ? produit.nom.substring(0, 28, ) + "..." : produit.nom}}
              </div>
              <div>
                {{ parseFloat(produit.prix_ttc * (1 - (produit.promo / 100)), ).toFixed(2, ) }} €
              </div>
            </div>
          </router-link>
        </Slide>
        <template #arrows="{ changeSlide, boundLeft, boundRight, }">
          <button
            class="slide-left"
            v-if="!boundLeft"
            @click="changeSlide(-1, )"
          >
            <i class="fa fa-arrow-left" />
          </button>

          <button
            class="slide-right"
            v-if="!boundRight"
            @click="changeSlide(1, )"
          >
            <i class="fa fa-arrow-right" />
          </button>
        </template>
      </Carousel>
    </div>
    <p class="see-more">
      <router-link
        class="gradient-text"
        :to="{name: 'ventes', }"
        title="Voir tous les vélos disponibles à la vente"
      >
        Découvrir tout nos vélos
      </router-link>
    </p>
  </section>
</template>

<script>
import { Carousel, Slide } from "vue-snap";
import "vue-snap/dist/vue-snap.css";
import config from "@/config";

export default {
  name: "PreviewVentes",
  components: {
    Carousel, Slide,
  },
  data() {
    return {
      produits: [],
      hover: false,
    };
  },
  computed: {
    config() {
      return config;
    },
  },
  mounted() {
    fetch(`${config.api}/api/produits`)
      .then((res) => res.json())
      .then((res) => {
        this.produits = res.results;
      });

    setInterval(() => {
      const carousel = this.$refs.carousel;
      if (carousel && !this.hover) {
        carousel.changeSlide(1);
        if (carousel.boundRight) {
          carousel.goToSlide(0);
        }
      }
    }, 4000);
  },
};
</script>

<style lang="scss">
@use "@/assets/styles/variables.scss" as *;

#preview_ventes {
  .see-more {
    text-align: center;
  }

  .VueCarousel-inner {
    width: 100%;
  }
  // .VueCarousel-slide-active {
  //   visibility: visible !important;
  // }
  .VueCarousel-navigation button {
    color: white;
  }
  .carousel {
    height: 22rem;
    margin: 5rem 0;
    > div > div {
      display: flex;
      gap: 3rem;
    }
    .glass {
      width: 22rem;
      // background: rgba(255, 255, 255, 0.5);
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 1rem;
      a {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 1rem;
        align-items: center;
        color: inherit;
        text-decoration: none;
        div {
          text-align: center;
        }
      }
    }
    figure {
      height: 12rem;
      margin: 0 0 0.5rem 0;
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }
  .see-more {
    font-size: 2rem;
  }

  .title {
    font-size: 1.2rem;
  }
  .promo {
    font-size: 1.2rem;
    color: white;
    margin-bottom: 1rem;
  }

  .carousel {
    @include media-medium() {
      margin-left: 2rem;
      margin-right: 2rem;
    }
    @include media-small() {
      margin-left: 4rem;
      margin-right: 4rem;
    }
  }
  .carousel-multiple {
    .carousel-slide {
      flex: 0 1 0;
      width: unset;
      a {
        width: 21rem;
        height: 19rem;
        @include media-medium() {
          width: 24rem;
          height: 18rem;
        }
        @include media-xsmall() {
          width: 19rem;
        }
      }
    }
    .vs-carousel__wrapper {
      gap: 1rem;
    }
    .slide-right, .slide-left {
      background: none;
      border: none;
      color: var(--white-color);
      font-size: 3rem;
      padding: 0.5rem;
      position: absolute;
      top: 50%;
      cursor: pointer;
      &:hover {
        color: var(--ascent-color);
      }
    }
    .slide-right {
      right: 0;
      transform: translate(130%, -50%);
    }
    .slide-left {
      left: 0;
      transform: translate(-130%, -50%);
    }
  }
}
</style>
