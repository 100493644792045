<template>
  <nav
    id="nav"
    class="glass"
    :class="{
      'nav-fixed': isNavVisible,
      mobile,
    }"
  >
    <template v-if="mobile">
      <router-link
        v-if="!toggleMobile"
        class="logo"
        :to="{hash: '#hero', name: 'site', }"
        title="Retour à l'accueil"
      >
        <img
          :src="require('@/assets/img/logo.svg', )"
          alt="Logo"
          loading="lazy"
        />
      </router-link>
      <div v-if="toggleMobile">
        <ul>
          <li>
            <router-link
              :to="{hash: '#horaires', name: 'site', }"
              @click="toggleMobile = !toggleMobile"
              title="Voir nos horaires"
            >
              Horaires
            </router-link>
          </li>
          <li>
            <router-link
              :to="{hash: '#prix', name: 'site', }"
              @click="toggleMobile = !toggleMobile"
              title="Voir nos tarifs"
            >
            Tarifs
            </router-link>
          </li>
          <li>
            <router-link
              :to="{name: 'location', }"
              title="Location"
            >
            Location
            </router-link>
          </li>
          <li>
            <router-link
              :to="{hash: '#contact', name: 'site', }"
              @click="toggleMobile = !toggleMobile"
              title="Nous contacter"
            >
            Contact
            </router-link>
          </li>
          <li>
            <router-link
              class="button"
              :to="{ name: 'ventes', }"
              title="Voir nos vélos en vente"
            >
              Nos vélos
            </router-link>
          </li>
          <li>
            <button @click="$store.dispatch('changeColor', )">
              <i class="fas fa-sun" />
              /
              <i class="fas fa-moon" />
            </button>
          </li>
        </ul>
      </div>
      <button
        @click="toggleMobile = !toggleMobile"
      >
        <i
          class="fa"
          :class="toggleMobile ? 'fa-times' : 'fa-bars'"
        />
      </button>
    </template>
    <template v-else>
      <router-link
        class="logo"
        :to="{hash: '#hero', name: 'site', }"
        title="Retour à l'accueil"
      >
        <img
          :src="require('@/assets/img/logo.svg', )"
          alt="Logo"
          loading="lazy"
        />
      </router-link>
      <ul>
        <li>
          <router-link
            :to="{hash: '#horaires', name: 'site', }"
            title="Voir nos horaires"
          >
            Horaires
          </router-link>
        </li>
        <li>
          <router-link
            :to="{hash: '#prix', name: 'site', }"
            title="Voir nos tarifs"
          >
          Tarifs
          </router-link>
        </li>
        <li>
          <router-link
            :to="{name: 'location', }"
            title="Location"
          >
          Location
          </router-link>
        </li>
        <li>
          <router-link
            :to="{hash: '#contact', name: 'site', }"
            title="Nous contacter"
          >
          Contact
          </router-link>
        </li>
        <li>
          <router-link
            class="button"
            :to="{ name: 'ventes', }"
            title="Voir nos vélos en vente"
          >
            Nos vélos
          </router-link>
        </li>
      </ul>
      <!-- TODO a => button -->
      <a
        @click="$store.dispatch('changeColor', )"
        title="Changer le thème"
      >
        <i class="fas fa-sun" />
        /
        <i class="fas fa-moon" />
      </a>
    </template>
  </nav>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "Nav",
  data() {
    return {
      isNavVisible: true, // Contrôle la visibilité du nav
      lastScrollPosition: 0, // Stocke la dernière position de défilement
      toggleMobile: false,
      mobile: false,
    };
  },
  computed: {
    ...mapGetters(["notPreRendered"]),
  },
  mounted() {
    if (this.notPreRendered) {
      window.onresize = () => {
        this.mobile = window.matchMedia("(max-width: 800px)").matches;
      };
      this.mobile = window.matchMedia("(max-width: 800px)").matches;
    }

    window.addEventListener("scroll", this.handleScroll);
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    handleScroll() {
      const currentScrollPosition = window.pageYOffset || document.documentElement.scrollTop;
      const threshold = 50; // Seuil de déplacement
      const splitSection = document.getElementById("split"); // Obtention de la section #split

      // Position de la section #split par rapport au haut de la page
      const splitPosition = splitSection?.offsetTop || 0;

      // Calcule la distance parcourue depuis le dernier scroll
      const scrollDistance = Math.abs(currentScrollPosition - this.lastScrollPosition);

      if (scrollDistance >= threshold) { // Ne réagit que si le scroll dépasse le seuil
        if (currentScrollPosition > this.lastScrollPosition) {
          // Masquer le nav si on descend ET si on a passé la section #split
          if (currentScrollPosition > splitPosition) {
            this.isNavVisible = false; // Descendre masque le nav après #split
          }
        } else {
          // Afficher le nav si on monte, indépendamment de la position de #split
          this.isNavVisible = true; // Monter montre le nav
        }
        // Mise à jour de la dernière position de défilement seulement après réaction
        this.lastScrollPosition = currentScrollPosition;
      }
    },
  },
};
</script>

<style lang="scss">
@use "@/assets/styles/variables.scss" as *;

@mixin media-mobile-nav() {
  @media screen and (max-width: 1000px){
    @content;
  }
}

#nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  top: -10rem;
  width: 80%;
  left: 50%;
  transform: translateX(-50%);
  padding: 1rem 6rem;
  z-index: 999;
  box-shadow: 0 0 3.5rem 0 var(--black-light-5);
  transition: top 0.5s;
  @include media-mobile-nav() {
    padding: 1rem 3rem;
  }

  &.nav-fixed {
    top: 1rem;
  }

  background: var(--black-light-5);

  .logo img {
    width: 10rem;
    @include media-mobile-nav() {
      width: 5rem;
    }
  }

  ul {
    display: flex;
    justify-content: space-between;
    list-style: none;
    gap: 3rem;

    @include media-mobile-nav() {
      gap: 1.5rem;
    }
  }

  a {
    cursor: pointer;
    text-decoration: none;
  }

  a:not(.button) {
    color: var(--white-color);
    font-weight: bold;
    &:hover {
      @include gradient-text();
    }
  }

  &.mobile {
    padding: 1.5rem;
    left: unset;
    width: fit-content;
    transform: unset;
    right: 15rem;
    @include media-medium() { right: 5rem; }
    @include media-small() { right: 1rem; }

    button {
      background: none;
      border: none;
      margin-top: 4px;
      color: var(--white-color);
      font-size: 2rem;
      cursor: pointer;
      align-self: flex-start;
    }

    ul {
      margin: 0 2rem 0 0;
      padding-left: 1rem;
      @include media-small() {
        flex-direction: column;
        text-align: center;
        gap: 2rem;
      }
    }

    .logo img {
      height: 3rem;
      margin-right: 1rem;
      width: unset;
    }
  }

}
</style>
