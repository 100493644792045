<template>
  <section id="ventes">
      <div class="container">
          <h2>Revendeur officiel</h2>
          <div class="brands">
              <div class="brand">
                  <figure>
                    <img
                      src="/marques/levit.jpeg"
                      alt="LEVIT"
                    />
                  </figure>
                  <p class="gradient-text">LEVIT</p>
                  <p>Des VAE innovants pour une mobilité urbaine durable.</p>
              </div>
              <div class="brand">
                  <figure>
                    <img
                      src="/marques/focus.jpeg"
                      alt="Focus"
                    />
                  </figure>
                  <p class="gradient-text">FOCUS</p>
                  <p>Vélos de route et VTT conçus pour la performance et l'aventure.</p>
              </div>
              <div class="brand">
                <figure>
                  <img
                    src="/marques/bertin.jpeg"
                    alt="Bertin"
                  />
                </figure>
                  <p class="gradient-text">Bertin</p>
                  <p>Le savoir-faire français au service de vélos de qualité supérieure.</p>
              </div>
              <div class="brand">
                  <figure>
                    <img
                      src="/marques/ultima.jpeg"
                      alt="Ultima"
                    />
                  </figure>
                  <p class="gradient-text">Ultima mobility</p>
                  <p>Excellence et innovation dans la conception de vélos électriques.</p>
              </div>
          </div>
      </div>
  </section>
</template>

<script>
export default {
  name: "Split",
};
</script>

<style lang="scss">
#ventes {
    color: var(--white-color);
    padding: 50px 0;

    h2 {
        margin-bottom: 30px;
    }
    .brands {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        .brand {
            width: 240px;
            margin: 0 15px 30px;
            text-align: center;
            figure {
              aspect-ratio: 1/1;
              display: flex;
              justify-content: center;
              align-items: center;
              background: white;
              border-radius: 1rem;
              overflow: hidden;
              padding: 0.5rem;
              img {
                  width: 100%;
                  max-width: 200px;
              }
            }
            p {
                color: var(--white-color);
                font-size: 14px;
                margin: 0;
                &.gradient-text {
                  font-size: 1.5rem;
                  margin: 0;
                }
            }
        }
    }

}
</style>
