<template>
  <section id="hero">
    <i class="fa fa-hammer" />
    <i class="fa fa-store" />
    <i
      v-if="!mobile"
      class="fa fa-bicycle"
    />
    <h3 class="gradient-text">
      Vente, entretien et réparation de cycles.
    </h3>
    <h1>
      Cyclo Truck, le choix local
    </h1>
    <h2>
      Faites de chaque trajet une nouvelle aventure sans tracas
      grâce à notre expertise
      en magasin à <span class="gradient-text">Bischoffsheim</span> et à domicile.
    </h2>
    <i class="fa fa-arrow-down" />
  </section>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "Hero",
  data() {
    return {
      mobile: false,
    };
  },
  computed: {
    ...mapGetters(["notPreRendered"]),
  },
  mounted() {
    if (this.notPreRendered) {
      window.onresize = () => {
        this.mobile = window.matchMedia("(max-width: 800px)").matches;
      };
      this.mobile = window.matchMedia("(max-width: 800px)").matches;
    }
  },
};
</script>

<style lang="scss">
@use "@/assets/styles/variables.scss" as *;

#hero {
  height: 85%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 15rem 10rem 0rem 10rem;
  @include media-small() {
    padding: 8rem 5rem 0rem 5rem;
  }
  background: linear-gradient(0deg, var(--black-color) 70%, var(--black-light-5) 100%);
  h1, h2, h3 {
    margin: 0;
  }
  h1 {
      text-align: center;
      font-size: 5rem;
      @include media-small() {
        font-size: 4rem;
        margin: 2rem;
      }
      font-weight: 900;
      margin-top: 3rem;
  }

  h2 {
    font-size: 3.5rem;
    @include media-small() {
        font-size: 2rem;
    }
    text-align: center;
    color: var(--ascent-color);
  }

  .fa-hammer, .fa-store, .fa-bicycle {
    top: 10%;
    right: 10%;
    @include bg-icon();
    display: block !important;
    text-align: center;
    font-size: 15rem;
    @include media-small() {
      font-size: 10rem;
    }
  }
  .fa-hammer {
    transform: scale(-1, 1);
    top: 20%;
  }
  .fa-store {
    right: unset;
    left: 10%;
  }
  .fa-bicycle {
    bottom: 20%;
    top: unset;
    left: 25%;
    @include media-small() {
      bottom: 12%;
      left: 50%;
      transform: translateX(-50%);
    }
  }
  .fa-arrow-down {
    margin-top: 5rem;
    font-size: 5rem;
    color: var(--black-light-30);
    z-index: 1;
  }
}
</style>
