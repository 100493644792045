<template>
  <section id="split">
    <img
      v-if="lightMode"
      :src="require('@/assets/newimg/shop_light.webp', )"
      alt="Illustration"
    />
    <img
      v-else
      :src="require('@/assets/newimg/shop_dark.webp', )"
      alt="Illustration"
    />
  </section>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "Split",
  computed: {
    ...mapGetters(["lightMode"]),
  },
};
</script>

<style lang="scss">
@use "@/assets/styles/variables.scss" as *;

#split {
  position: relative;
  width: 100%;
  background: var(--black-color);
  img:not(.over) {
    filter: drop-shadow(0 -10px 20px var(--white-color-split));
    width: 100%;
  }
}
</style>
