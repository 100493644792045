<template>
    <div id="content">
      <Nav />
      <Hero />
      <Split />
      <Avis class="desktop-avis"/>
      <Horaires />
      <PreviewVentes />
      <Ventes />
      <Prix />
      <Marques />
      <Avis class="mobile-avis"/>
      <Contact />
    </div>
</template>

<script>
import Split from "@/components/Split.vue";
import Avis from "@/components/Avis.vue";
import Horaires from "@/components/Horaires.vue";
import Prix from "@/components/Prix.vue";
import Hero from "@/components/Hero.vue";
import PreviewVentes from "@/components/PreviewVentes.vue";
import Contact from "@/components/Contact.vue";
import Nav from "@/components/Nav.vue";
import Marques from "@/components/Marques.vue";
import Ventes from "@/components/Ventes.vue";

export default {
  name: "SiteNew",
  components: {
    Split,
    Avis,
    Horaires,
    Prix,
    Hero,
    PreviewVentes,
    Contact,
    Nav,
    Marques,
    Ventes,
  },
  data() {
    return {
    };
  },
  methods: {
  },
  mounted() {
    this.$store.dispatch("changeColor", true);
  },
};
</script>

<style lang="scss">
@use "@/assets/styles/variables.scss" as *;
@import '~@fortawesome/fontawesome-free/css/all.min.css';
@import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,400;0,700;1,800&display=swap');

@font-face {
  font-family: 'Averta';
  src: url('/regular.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Averta';
  src: url('/bold.woff2') format('woff2');
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: 'Averta';
  src: url('/extrabold.woff2') format('woff2');
  font-weight: 900;
  font-style: normal;
}

.green .fa {
  color: green;
}
.red .fa {
  color: red;
}

.mobile-avis {
  display: none;
}
@include media-small() {
  .mobile-avis {
    display: block;
  }
  .desktop-avis {
    display: none;
  }
}

* {
  box-sizing: border-box;
}

:root {
  --black-color: #010912;
  --black-color-split: #01091242;
  --white-color: #E0E0E0;
  --white-color-split: #e0e0e029;
  --ascent-color: #186181;
  --ascent-color-light-5: #88bed6;//lighten(var(--ascent-color), 5%)
  --ascent-color-light-10: #9bc9dc; //lighten(var(--ascent-color), 10%)
  --ascent-color-light-15: #aed3e3; //lighten(var(--ascent-color), 10%)
  --ascent-color-light-20: #c1dde9;//lighten(var(--ascent-color), 20%)
  --ascent-color-light-45: #e5f1f7;//lighten(var(--ascent-color), 45%)
  --ascent-color-dark-30: #2c667f;//darken(var(--ascent-color), 30%)
  --black-light-5: #02152a;//lighten(var(--black-color), 5%)
  --black-light-30: #0951a3;//lighten(var(--black-color), 30%)

  --secondary-color: #27aae1;
}

html {
  font-size: 62.5%; /* équivalence "10px" sur l'élément racine */
  font-family: "Averta", sans-serif;
  scroll-behavior: smooth;
}

body {
  margin: 0 0 10rem 0;
  overflow-y: auto;
  overflow-x: hidden;
  height: 100vh;
  font-size: 1.6em; /* taille de base pour tous les éléments équivalent 16px */
  @include media-small() {
    font-size: 1.4em;
  }
  background: var(--black-color);
  color: var(--white-color);
}

h2 {
  text-align: center;
  font-size: 5rem;
  @include media-small() {
    font-size: 3rem;
  }
  margin: 0;
}
h3 {
  text-align: center;
  margin: 0;
}

.spaced {
  margin: 10rem 0 7rem 0;
}

.button {
  background: var(--white-color);
  color: var(--black-color);
  padding: 1rem 2rem;
  border-radius: 0.5rem;
  border: 1px solid var(--black-color);
  @include media-small() {
    padding: 0.5rem 1rem;
  }
  &:hover:not(:disabled) {
    cursor: pointer;
    background: linear-gradient(to top, var(--ascent-color-light-5) 30%, var(--ascent-color-light-15) 60%)
  }
  &:disabled {
    opacity: 0.2;
  }
  &.success {
    border: 1px solid green;
    background: #7dd37d;
    color: black;
    &:hover:not(:disabled) {
      background: #65bb65;
      color: #0b4506;
    }
  }
}

section:not(#hero, #split, #reservation){
  margin-top: 15rem;
  padding: 0 15rem;
  @include media-medium() { padding: 0 5rem; }
  @include media-small() { padding: 0 2rem; }
}

.gradient-text {
  @include gradient-text();
}

.glass {
  background: radial-gradient(
    94.91% 94.91% at 50% 50%, rgba(1, 69, 137, 0.22) 0%, rgba(0, 0, 0, 0) 100%
  ), rgba(5, 24, 48, 0.2);
  padding: 1rem;

  backdrop-filter: blur( 4px );
  -webkit-backdrop-filter: blur( 4px );
  border-radius: 10px;
  border: 1px solid rgba(48, 137, 255, 0.1);
}

// #app, #content {
//   height: 100%;
// }

#content section:last-of-type {
  padding-bottom: 10rem;
}

</style>
