import Vue from "vue";
import VueRouter from "vue-router";
import Site from "@/views/SiteNew.vue";
import Legals from "@/views/Legals.vue";
import Vente from "@/views/VenteNew.vue";
import ProductDetails from "@/views/ProductDetails.vue";
import Location from "@/views/Location.vue";
import LocationDetails from "@/views/LocationDetails.vue";
import Resa from "@/views/Resa.vue";
import CGL from "@/views/CGL.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "site",
    component: Site,
  },
  {
    path: "/produits",
    name: "ventes",
    component: Vente,
  },
  {
    path: "/reservation/:id",
    name: "resa",
    component: Resa,
  },
  {
    path: "/produits/:id",
    name: "product-details",
    component: ProductDetails,
  },
  {
    path: "/location",
    name: "location",
    component: Location,
  },
  {
    path: "/location/:id",
    name: "location-details",
    component: LocationDetails,
  },
  {
    path: "/legals",
    name: "legals",
    component: Legals,
  },
  {
    path: "/conditions-generales-location",
    name: "cgl",
    component: CGL,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to) {
    if (to.hash) {
      const hashElement = document.querySelector(to.hash);
      if (hashElement) {
        setTimeout(() => {
          hashElement.scrollIntoView({ behavior: "smooth", block: "center" });
        }, 300);
      } else {
        setTimeout(() => {
          document.querySelector(to.hash).scrollIntoView({ behavior: "smooth", block: "center" });
        }, 300);
      }
    } else {
      window.scrollTo(0, 0);
    }
  },
});

export default router;
