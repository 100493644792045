<template>
  <section id="horaires">
    <h2>
      Alexandre, passionné de vélo
    </h2>
    <h3>
      Titulaire du CQP
      <span class="gradient-text">technicien cycle</span>
      me permettant de vous proposer mes services pour l’entretien et la réparation de vos vélos.
    </h3>
    <div class="blocks">
      <div class="element shop-block">
        <i class="fa fa-store" />
        <figure class="glass">
          <img
            :src="require('@/assets/newimg/boutique.webp', )"
            alt="Image de la boutique"
            loading="lazy"
          />
        </figure>
        <div class="text glass">
          <h3>En magasin</h3>
          <a
          href="https://www.google.com/maps/place/63+Rue+Principale,+67870+Bischoffsheim/@48.4869197,7.4868745,17z
/data=!3m1!4b1!4m6!3m5!1s0x4796b2a63583365f:0xe004987915b84da5!
8m2!3d48.4869162!4d7.4894494!16s%2Fg%2F11c1bc64dt?entry=ttu"
          target="_blank"
          title="Voir sur Google Maps"
          >
            <i class="fa fa-map-marker-alt" /> 63 Rue Principale, 67870 Bischoffsheim
          </a>
          <ul>
            <li
              v-for="(h, index) in magasin"
              :key="h[0]"
              :class="{ active: dayIndex === index, }"
            >
              <div>
                {{ h[0] }}
              </div>
              <div v-html="h[1]" />
            </li>
          </ul>
        </div>
        <div class="space" />
      </div>

      <div class="element deplacement-block">
        <i class="fa fa-truck" />
        <div class="space" />
        <figure class="glass">
          <img
            :src="require('@/assets/newimg/deplacement.webp', )"
            alt="Image de la camionnette en déplacement"
            loading="lazy"
          />
        </figure>
        <div class="text glass">
          <h3>En déplacement</h3>
          <span>
            Au travail, à la maison, chez mamie...
          </span>
          <div>
            Sur rendez-vous au
            <a
              class="tel"
              :href="`tel:${config.tel}`"
              title="Nous appeler"
            >
              {{config.tel}}
            </a>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import config from "@/config";

export default {
  name: "horaires",
  data() {
    return {
      magasin: [
        ["Lundi", "09h00 - 12h00<br />14h00 - 18h00"],
        ["Mardi", "09h00 - 12h00<br />14h00 - 18h00"],
        ["Mercredi", "09h00 - 12h00<br />14h00 - 18h00"],
        ["Jeudi", "Fermé"],
        ["Vendredi", "09h00 - 12h00<br />14h00 - 18h00"],
        ["Samedi", "14h00 - 17h"],
      ],
      img: "https://files.oaiusercontent.com/file-KmKAH4AYHrCEjGxUuSKuGWCj?se=2024-02-17T20%3A27%3A20Z&sp=r&sv=2021-08-"
      + "06&sr=b&rscc=max-age%3D31536000%2C%20immutable&rscd=attachment%3B%20filename%3De878f052-9d05-4da9-a812-0793de"
      + "38efe4.webp&sig=q8RhznW0GJXGE9FrQWQa3ZsjlTRW71ha4N/wlq7ubrI%3D",
    };
  },
  computed: {
    dayIndex() {
      return new Date().getDay() - 1;
    },
    config() {
      return config;
    },
  },
};
</script>

<style lang="scss">
@use "@/assets/styles/variables.scss" as *;

section#horaires {
  > h2, > h3 {
    margin: 0;
    text-align: center;
  }
  .blocks {
    margin-top: 10rem;
    font-size: 1.8rem;
    @include media-small() {
      font-size: 1.5rem;
      padding: 0 5rem;
    }
    @include media-xsmall() {
      padding: 0 3rem;
    }
  }
  .element {
    display: flex;

    @include media-small() {
      flex-direction: column;
      .space {
        display: none;
      }
    }

    & + .element {
      margin-top: 5rem;
      .text > div {
        margin-top: 3rem;
      }
    }
    > * {
      flex: 2;
    }
    .space {
      flex: 1;
    }
    figure, .text {
      margin: 0;
      overflow: hidden;
    }
    figure {
      border-radius: 1rem;
      display: flex;

      @include media-big() {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }
      @include media-medium() {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        position: relative;
        z-index: 999;
      }
    }
    .text {
      padding: 1rem;
      @include media-big() {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }
      @include media-medium() {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
      }
      @include media-small() {
        padding: 3rem;
      }
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      h3 {
        margin: 0;
      }
      a, span {
        margin: 0.5rem 0;
        color: var(--secondary-color);
        text-align: center;
        text-decoration: none;

        &:not(.tel) {
          display: block;
        }
      }
      .tel {
        @include gradient-text();
      }
    }

    ul {
      padding: 0;
      margin: auto;
      display: inline-block;
      list-style: none;
      li {
        display: flex;
        margin-bottom: 1rem;
        align-items: center;
        div {
          display: inline-block;
        }
        div:first-of-type {
          width: 9rem;
        }
        div:nth-of-type(2) {
          flex: 1;
          text-align: center;
        }
        &.active {
          // color: var(--ascent-color);
          font-weight: 900;
        }
      }
    }
  }

  .shop-block {
    position: relative;
    .fa-store {
      @include bg-icon(15rem);
      top: 40%;
      right: 0rem;
      transform: translateY(-50%) rotate(-15deg);
    }
  }

  .deplacement-block {
    position: relative;
    .fa-truck {
      @include bg-icon(15rem);
      top: 60%;
      left: -5rem;
      transform: translateY(-50%) rotate(10deg);
    }
  }
}
</style>
