<template>
  <section
    v-if="resa"
    class="content"
    id="reservation"
  >
    <header>
      <a
        href="/"
        title="Retourner à l'accueil"
      >
        <img
          :src="require('@/assets/img/logo.svg',)"
          alt="Logo"
          loading="lazy"
        />
      </a>
    </header>
    <main>
      <h2>Réservation de créneau</h2>
      <h3>{{ resa.titre }} - {{ date }}</h3>
      <p
        v-if="resa.adresse"
        class="adresse"
      >
        Adresse: {{ resa.adresse }}
      </p>
      <p
        class="description"
        v-if="description"
        v-html="description"
      />
      <div
        v-if="resaState === 'ended'"
        class="ended"
      >
        <h2>Oups...</h2>
        <img
          :src="require('@/assets/img/time.svg',)"
          alt="Illustration"
        />
        <p>L'évenement est fini.</p>
        <p>N'hésitez pas à nous contacter au {{ config.tel }} si besoin.
        </p>
      </div>
      <form
        v-else-if="selectedCreneau"
        class="form glass"
        @submit.prevent="submit"
      >
        <template v-if="resaState === null">
          <h3>
            Réserver le créneau de {{ selectedCreneau }}
          </h3>
          <div>
            <div class="floating-label">
              <input
                placeholder="Nom*"
                required
                v-model="form.nom"
              />
              <label>Nom*</label>
            </div>
            <div class="floating-label">
              <input
                placeholder="Prénom*"
                required
                v-model="form.prenom"
              />
              <label>Prénom*</label>
            </div>
            <div class="floating-label">
              <input
                placeholder="Courriel* (email)"
                type="email"
                required
                v-model="form.email"
              />
              <label>Courriel*</label>
            </div>
            <div class="floating-label">
              <input
                placeholder="Téléphone*"
                type="tel"
                required
                v-model="form.tel"
              />
              <label>Téléphone*</label>
            </div>
            <div class="floating-label">
              <textarea
                placeholder="Adresse postale* (rue, code postal, ville...)"
                v-model="form.adresse"
                required
                rows="4"
              />
              <label>Adresse postale*</label>
            </div>
            <div class="floating-label">
              <textarea
                placeholder="Commentaire"
                v-model="form.commentaire"
              />
              <label>Commentaire</label>
            </div>
            <div class="buttons">
              <button
                class="button"
                @click="selectedCreneau = null; resaState=null;"
              >
                Choisir un autre créneau
              </button>
              <button
              class="button"
                type="submit"
              >
                Valider la réservation
              </button>
            </div>
          </div>
        </template>
        <template v-else-if="resaState === 'taken'">
          <div class="error">
            Oups... Quelqu'un a été plus rapide que vous, ce créneau vient d'être réservé. <br /><br />
            Veuillez sélectionner un nouveau créneau pour poursuivre votre réservation.
          </div>
          <div class="buttons">
            <button @click="selectedCreneau = null; resaState=null;">Choisir un autre créneau</button>
          </div>
        </template>
        <template v-else-if="resaState === 'error'">
          <div class="error">
            Oups... Une erreur s'est produite.<br /><br />
            Vous pouvez réessayer un autre créneau ou nous contacter au {{ config.tel }}
          </div>
          <div class="buttons">
            <button @click="selectedCreneau = null; resaState=null;">Choisir un autre créneau</button>
          </div>
        </template>
        <template v-else-if="resaState === 'ok'">
          <div class="success">
            Merci ! Votre réservation a bien été validée.<br /><br />
            Pour rappel, Cyclo Truck s'occupera de votre bolide le {{ date }} lors du créneau {{ selectedCreneau }}.
          </div>
        </template>
      </form>
      <div
        v-else
        id="calendar-view"
      >
        <table>
          <tbody>
            <tr
              v-for="c in creneaux"
              :key="c"
              @click="resa.creneaux_indisponibles.includes(c, ) ? null : selectCreneau(c, )"
            >
              <td class="glass">{{ c }}</td>
              <td
                class="glass"
                :class="{nope: resa.creneaux_indisponibles.includes(c, ), }"
              >
                {{ resa.creneaux_indisponibles.includes(c, ) ? "Indisponible" : "Disponible" }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </main>
  </section>
</template>

<script>
import moment from "moment";
import config from "@/config";

export default {
  name: "Resa",
  components: {
  },
  data() {
    return {
      test: false,
      selectedCreneau: null,
      form: {
        nom: null,
        prenom: null,
        email: null,
        tel: null,
        commentaire: null,
        adresse: null,
      },
      resa: null,
      resaState: null,
    };
  },
  computed: {
    description() {
      return this.resa.description.replaceAll("\r\n", "<br />");
    },
    config() {
      return config;
    },
    creneaux() {
      const start = moment(this.resa.heure_debut);
      const end = moment(this.resa.heure_fin);
      const interval = this.resa.duree_creneau;

      // const nbCreneau = ((60 / interval) * (end - start)) - 1;

      const creneaux = [];

      const tmp = start;
      while (tmp < end) {
        let creneau = "";

        creneau += `${tmp.format("HH:mm")} - `;
        tmp.add(interval, "minutes");
        creneau += tmp.format("HH:mm");

        creneaux.push(creneau);
      }

      return creneaux;
    },
    date() {
      return moment(this.resa.heure_debut).format("DD/MM/YYYY");
    },
  },
  mounted() {
    this.getResa();
    setInterval(() => this.getResa(), 10 * 1000);
    this.$store.dispatch("changeColor", true);
  },
  methods: {
    getResa() {
      fetch(`${config.api}/api/reservation/${this.$route.params.id}`)
        .then((res) => res.json())
        .then((res) => {
          if (res.error === "ended") {
            this.resaState = "ended";
          } else if (res.error) {
            this.$router.replace("/");
          }

          if (res.resa) {
            this.resa = res.resa;
          }
        });
    },
    selectCreneau(c) {
      this.selectedCreneau = c;
      this.resaState = null;
    },
    submit() {
      fetch(
        `${config.api}/api/reservation/${this.$route.params.id}`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            ...this.form,
            creneau: this.selectedCreneau,
          }),
        },
      )
        .then((res) => res.json())
        .then((res) => {
          if (res.error === "ended") {
            this.resaState = "ended";
          } else if (res.error === "taken") {
            this.resaState = "taken";
          } else if (res.resa) {
            this.resaState = "ok";
          } else {
            this.resaState = "error";
          }

          if (res.resa) {
            this.resa = res.resa;
          }
        });
    },
  },
};
</script>

<style lang="scss">
@use "@/assets/styles/variables.scss" as *;

#reservation {
  margin-top: 5rem;
  padding: 0 15rem;

  header img {
    width: 8rem;
  }

  .adresse, .description {
    text-align: center;
  }
}

.floating-label {
  //border: 1px solid #d1d5db;
  border-radius: 0.25rem;
  position: relative;

  width: 75%;
  &:not(:last-child) {
    margin-bottom: 2.5rem;
  }

  input, textarea {
    border-radius: 0.25rem;
    border: none;
    padding: 0.5rem;
    width: 100%;
    &:not(:placeholder-shown) + label {
      background: #fff;
      transform: translate(0, -50%);
      opacity: 1;
      color: black;
      display: block;
    }
  }
  label {
    left: 0;
    position: absolute;
    display: none;
    top: -0.9rem;
    opacity: 0;
    transition: top left 200ms;
    border-bottom: 1px solid black;
    font-size: 1.3rem;
    padding: 0 1rem;
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
  }
}

#calendar-view {
  display: flex;
  justify-content: center;
  table {
    width: 75%;
  }

  td {
    padding: 1rem;
    text-align: center;
    border-radius: 0;
  }
  tr {
    background: radial-gradient(
        94.91% 94.91% at 50% 50%, rgba(1, 69, 137, 0.22) 0%, rgba(0, 0, 0, 0) 100%
      ), rgba(5, 24, 48, 0.2) !important;

    &:not(.nope) {
      cursor: pointer;
      &:hover {
        color: var(--ascent-color);
      }
    }
  }
}

.form {
  padding: 2rem;
  > div {
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  h3 {
    margin-bottom: 2rem;
  }
  .error {
    color: #c51515;
    margin-bottom: 1rem;
    text-align: center;
  }
  .success {
    color: green;
    text-align: center;
  }
}
</style>
