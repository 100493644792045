export default {
  api: "https://cyclo-truck.fr/back",
  // api: window.location.hostname.includes("cyclo") ? "/back" : "http://localhost:8002",
  adresse: {
    nom: "Cyclo Truck",
    rue: "1 rue des Coquelicots",
    cp: "67870",
    ville: "Griesheim-près-Molsheim",
    pays: "France",
  },
  telMag: "09 83 56 12 88",
  tel: "06 52 32 19 39",
  email: "cyclo.truck@gmail.com",
  siret: "91157815100019",
  horaires: [9, 18],
};
